import React, { useEffect, useState } from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

const getAccessTokenProd = async (url: string, realm: string, credentials: GetCrendentials): Promise<string | Error> => {
    const body = new URLSearchParams(credentials);
    const response = await fetch(`${url}/auth/realms/${realm}/protocol/openid-connect/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body
    });

    const json = await response.json();
    return json.access_token;
}

export const NavigationSelectorComponent = (props: FieldExtensionComponentProps<string[]>) => {
    const [options, setOptions] = useState<Navigation[]>([]);
    const [selectedOption, setSelectedOption] = useState('');
    const config = useApi(configApiRef);
    const { formContext, onChange } = props;
    const { formData } = formContext;
    const aplicacao = formData.aplicacao;

    useEffect(() => {

        const URL = config.getOptionalString('gmsuiteconfigs.baseUrl');
        const RHSSO_URL = config.getOptionalString('gmsuiteconfigs.rhssoUrl');
        const RHSSO_REALM = config.getOptionalString('gmsuiteconfigs.realm');

        const data: GetCrendentials = {
            grant_type: 'client_credentials',
            scope: 'openid',
            client_id: config.getOptionalString('gmsuiteconfigs.clientId'),
            client_secret: config.getOptionalString('gmsuiteconfigs.clientSecret'),
        }

        if (URL && RHSSO_URL && RHSSO_REALM) {
            getAccessTokenProd(RHSSO_URL, RHSSO_REALM, data)
                .then((token) => {
                    if (typeof token === 'string') {
                        fetch(`${URL}/arquitetura-gmsuite-configs/menus/navigation?applicationName=${aplicacao}`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                setOptions(data);
                            })
                            .catch((error) => {
                                console.error('Erro ao buscar dados da API:', error);
                            });
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar token de acesso:', error);
                });
        }
    }, [aplicacao, config]);

    const handleChange = (event: any) => {
        setSelectedOption(event.target.value);
        onChange(event.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor='identificador'>Selecione uma opção</InputLabel>
            <Select
                id="identificador"
                aria-describedby='helper'
                value={selectedOption}
                onChange={handleChange}
            >
                {options?.map((option) => (
                    <MenuItem key={option.identifier} value={option.identifier}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText id='helper'>
                Selecione a aplicação onde o projeto será disponibilizado
            </FormHelperText>
        </FormControl>
    );
};
